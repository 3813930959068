<template>
	<div id="body">
		<!-- 通用banner布局-->
		<div id="banner">
			<el-carousel height="700px" :interval='5000'>
				<el-carousel-item v-for="(item,i) in list" :key="i">
					<img :src="item.url" >
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- PC页面布局 -->
		<div id="title">
			<div id="tit_mian">
				<div id="left">
					<span>云计算</span>
					<span>云存储</span>
					<span>跨平台</span>
				</div>
				<div id="right">
					同时支持电脑端 手机APP 同步使用 实时连接用户的新一代融恒售后管理系统！
				</div>
			</div>
		</div>
		<div id="list">
			<div id="list_main">
				<p>融恒售后管理系统全面覆盖以下行业</p>
				<div id="list_main_main">
					<div id="flex_box">
						医疗设备行业
					</div>
					<div id="flex_box">
						仪器仪表行业
					</div>
					<div id="flex_box">
						家用电器行业
					</div>
					<div id="flex_box">
						安防监控行业
					</div>
					<div id="flex_box">
						空调设备行业
					</div>
					<div id="flex_box">
						家居建材行业
					</div>
					<div id="flex_box">
						健身设备行业
					</div>
					<div id="flex_box">
						环保设备行业
					</div>
					<div id="flex_box">
						项目维保行业
					</div>
				</div>
			</div>
		</div>
		<div id="whatbox">
			<div id="whatbox_main">
				<p>什么是融恒售后管理系统</p>
				<div id="whatbox_main_box">
					<div id="whatbox_main_box_flex">
						<div id="tt">
							是一款简单易用的</br>高效售后管理系统
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BX001.jpg">
					</div>
					<div id="whatbox_main_box_flex">
						<div id="tt">
							是一套集拍照，信息与一体的</br>功能强大的售后管理工具
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BX002.jpg">
					</div>
					<div id="whatbox_main_box_flex">
						<div id="tt">
							是一个集手机应用和云技术</br>相结合的移动售后管理平台
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BX003.jpg">
					</div>
				</div>
			</div>
		</div>
		<div id="funbox">
			<div id="bgc">
				<span style="display: block;font-size: 35px; color: #fd3f31;">融恒售后有什么功能模块？</span>
				<span style=" color: #fd3f31;">十大功能模块，满足不同场景对公司的管理需求</span>
			</div>
			<div id="bott">
				<div id="bott_main">
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='weixin1'></icon-svg>
						<p>微信报修</p>
						<span>扫码报修，维修工单一键生成</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='paigong'></icon-svg>
						<p>工单派工</p>
						<span>接单派工，分配任务，高效便捷</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='dizhi'></icon-svg>
						<p>位置签到</p>
						<span>实时了解人员分布，任务进度轻松掌握</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='paizhao'></icon-svg>
						<p>拍照反馈</p>
						<span>手机拍照、实时反馈，现场情况清晰客观</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='daoqitixing1'></icon-svg>
						<p>到期提醒</p>
						<span>保修期限，设备保养日等重要时点提醒和预警</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='kehupingjia'></icon-svg>
						<p>客户评价</p>
						<span>回访及时准确，评价真实有效，考核有根有据</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='shebeiguanli-beijianguanli-beijiansheding'></icon-svg>
						<p>备件管理</p>
						<span>备件申请、领用、归还、库存，配件追溯可查</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='zhishiguanli'></icon-svg>
						<p>知识管理</p>
						<span>资料库、故障库实时查询，客户问题快捷解决</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='feiyongjiesuan'></icon-svg>
						<p>费用结算</p>
						<span>配件费、服务费自动结算，成本利润尽在掌握</span>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='xiaoshoudingdan'></icon-svg>
						<p>统计分析</p>
						<span>多维多角度数据分析，售后全局了然于胸</span>
					</div>
				</div>
			</div>
		</div>
		<div id="flowchart">
			<div id="flowbox">
				<span>
					融恒售后流程示意图
				</span>
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXflow.jpg">
			</div>
		</div>
		<div id="issbox">
			<div id="issbox_main">
				<div id="issbox_tit">
					<p>融恒售后能解决什么问题?</p>
					<span>售后服务管理中存在的普遍问题，轻松搞定！</span>
				</div>
				<div id="issbox_main_box">
					<div id="issbox_main_box_left">
						<div id="title_le">
							企业问题
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【任务跟踪难】</span>客户报修派谁去？进展如何？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【沟通协调难】</span>派工单是否收到？能去吗？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【过程监控难】</span>是否如期到达？几点开始维修？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【数据采集难】</span>设备历史数据有吗？在哪能看到？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【人员考评难】</span>工作是否完成？客户满意吗？</span>
						</div>
					</div>
					<div id="issbox_main_box_right">
						<div id="title_ri">
							解决方案
						</div>
						<div id="title_ri_mian">
							<p style="font-size: 22px;">重要事件提醒，关键节点预警</p>
							<span
								style="display:block;width: 80%;margin-left: 10%;">重要事件提醒，提供售后保修期限、设备保养周期、设备巡检日期等售后服务过程中重要时间
								点预警和提醒。</span>
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/ph.png">
					</div>
				</div>
			</div>
		</div>
		<div id="bottom">
			<div id="bottom_bgi">
				<div id="bottop">
					<p style="font-size: 40px;">融恒售后软件能给我带来什么好处？</p>
					<p>提高售后服务效率，增强企业竞争力，好处多多！</p>
				</div>
				<div id="bottom_flex">
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD001.jpg">
						<p>服务过程实时掌控，随时随地查看任务进展情况，方便管理</p>
					</div>
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD002.jpg">
						<p>公司管理人员、售后人员、客户共享平台，实现高效协同</p>
					</div>
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD003.jpg">
						<p>管理流程简单化，管理软件移动化，工具界面人性化，简单易用</p>
					</div>
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD004.jpg">
						<p>降低管理成本，提高客户满意度 ，提升品牌形象</p>
					</div>
				</div>
			</div>
		</div>
		<div id="fobox">
			<p style="font-size: 35px;">融恒售后，让售后更简单</p>
			<p>不止专业，更是业界一对一量身定制，领异标新的精准行业专属解决方案，打破通用化开发套路</p>
		</div>
		<!-- 手机官网页面 -->
		<div id="Phone_jxc_tit_Title_box">
			<p style="font-size: 25px;">云计算 云存储 跨平台</p>
			<p style="color: #999;">同时支持电脑端 手机APP 同步使用 实时连接用户的新一代融恒售后管理系统</p>
		</div>
		<div id="Phone_jxc_tit_img">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXimg.jpg">
			<p>电脑+手机APP 同步使用</p>
		</div>
		<div id="Phone_jxc_tit_btn">
			<p @click="phone_call"><i class="el-icon-phone-outline"></i>拨打电话</p>
			<p style="text-indent: none;"><a style="color: #FFFFFF; text-decoration:none"
					href="http://rhdgjzc.ronghengzn.com/" target='_blank'>注册试用</a></p>
		</div>
		<div id="Phone_Hy_box">
			<p>云售后管理系统全面覆盖以下行业</p>
			<div id="Phone_Hy_box_flex">
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='zu'></icon-svg>
					<p>医疗设备行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='yiqiyibiao'></icon-svg>
					<p>仪器仪表行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='xingzhuang'></icon-svg>
					<p>家用电器行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='jiankong'></icon-svg>
					<p>安防监控行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='kongtiao1'></icon-svg>
					<p>空调设备行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='jiancai'></icon-svg>
					<p>家居建材行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='jianshen'></icon-svg>
					<p>健身设备行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='huanbao'></icon-svg>
					<p>环保设备行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='wujingongju'></icon-svg>
					<p>项目维保行业</p>
				</div>
			</div>
		</div>
		<div id="Phone_what_jxc_box">
			<p>什么是融恒售后管理系统</p>
			<div id="Phone_what_jxc_box_flex">
				<div id="Phone_what_jxc_box_flex_box">
					<p>是一款简单易用的高效售后管理系统</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BX001.jpg">
				</div>
				<div id="Phone_what_jxc_box_flex_box">
					<p>是一套集拍照，信息与一体的功能强大的售后管理工具</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BX002.jpg">
				</div>
				<div id="Phone_what_jxc_box_flex_box">
					<p>是一个集手机应用和云技术相结合的移动售后管理平台</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BX003.jpg">
				</div>
			</div>
		</div>
		<div id="Phone_mode_box">
			<div id="phone_title">
				<p id="first_child">融恒售后有什么功能模块</p>
				<p>十大功能模块，满足不同场景对公司的管理需求</p>
			</div>
			<div id="Phone_mode_box_flex">
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='weixin1'></icon-svg>
					<p>微信报修</p>
					<span>扫码报修，维修工单一键生成</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='paigong'></icon-svg>
					<p>工单派工</p>
					<span>接单派工，分配任务，高效便捷</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='dizhi'></icon-svg>
					<p>位置签到</p>
					<span>实时了解人员分布，任务进度轻松掌握</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='paizhao'></icon-svg>
					<p>拍照反馈</p>
					<span>手机拍照、实时反馈，现场情况清晰客观</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='daoqitixing1'></icon-svg>
					<p>到期提醒</p>
					<span>保修期限，设备保养日等重要时点提醒和预警</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='kehupingjia'></icon-svg>
					<p>客户评价</p>
					<span>回访及时准确，评价真实有效，考核有根有据</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='shebeiguanli-beijianguanli-beijiansheding'></icon-svg>
					<p>备件管理</p>
					<span>备件申请、领用、归还、库存，配件追溯可查</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='zhishiguanli'></icon-svg>
					<p>知识管理</p>
					<span>资料库、故障库实时查询，客户问题快捷解决</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='feiyongjiesuan'></icon-svg>
					<p>费用结算</p>
					<span>配件费、服务费自动结算，成本利润尽在掌握</span>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='xiaoshoudingdan'></icon-svg>
					<p>统计分析</p>
					<span>多维多角度数据分析，售后全局了然于胸</span>
				</div>
			</div>
		</div>
		<div id="Phone_flow_img" style="margin-top: 5%;">
			<div id="Phone_flow_tit">
				融恒售后流程示意图
			</div>
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phone_flow_bx.jpg">
		</div>
		<div id="Phone_issue">
			<div id="Phone_issue_header">
				企业问题
			</div>
			<div id="Phone_issue_main">
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【任务跟踪难】</span>客户报修派谁去？进展如何？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【沟通协调难】</span>派工单是否收到？能去吗？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【过程监控难】</span>是否如期到达？几点开始维修？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【数据采集难】</span>设备历史数据有吗？在哪能看到？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【人员考评难】</span>工作是否完成？客户满意吗？</span>
				</div>
			</div>
		</div>
		<div id="Phone_solve_box">
			<div id="Phone_solve_box_header">
				解决方案
			</div>
			<div id="Phone_solve_box_main">
				<div id="Phone_solve_box_main_tit">
					<p>重要事件提醒，关键节点预警</p>

					<span>重要事件提醒，提供售后保修期限、设备保养周期、设备巡检日期等售后服务过程中重要时间 点预警和提醒。</span>
				</div>
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/ph.png">
			</div>
		</div>
		<div id="Phone_benefit_box">
			<div id="Phone_benefit_box_title">
				<p>云售后软件能给我带来什么好处？</p>
				<span>提高售后服务效率，增强企业竞争力，好处多多</span>
			</div>
			<div id="Phone_benefit_box_flex">
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD001.jpg">
					<p>服务过程实时掌控，随时随地查看任务进展情况，方便管理</p>
				</div>
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD002.jpg">
					<p>公司管理人员、售后人员、客户共享平台，实现高效协同</p>
				</div>
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD003.jpg">
					<p>管理流程简单化，管理软件移动化，工具界面人性化，简单易用</p>
				</div>
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXGOOD004.jpg">
					<p>降低管理成本，提高客户满意度 ，提升品牌形象</p>
				</div>
			</div>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default {
		components: {
			footers
		},
		data() {
			return {
				list:[{
					url:"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXBANNER001.jpg"
				},{
					url:"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXBANNER002.jpg"
				},{
					url:"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BXBANNER003.jpg"
				}]
			}
		},
		mounted() {},
		methods: {
			phone_call() {
				window.location.href = 'tel:4006182019'
			},
		}
	}
</script>

<style lang="less" scoped>
	@media screen and (min-width: 720px) {

		//PC banner样式
		#banner {
			width: 100%;
			height: 700px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#banner {
			width: 100%;
			height: 200px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (min-width: 720px) {

		//PC banner样式
		#title {
			width: 100%;
			height: 170px;
			border-bottom: 1px solid #f3f3f3;
			box-shadow: 0px 8px 16px rgb(234 234 234);

			#tit_mian {
				width: 66%;
				height: 100%;
				margin-left: 17%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				#left {
					width: 50%;

					span {
						margin-right: 3%;
						font-size: 30px;
					}
				}

				#right {
					color: #999;
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#title {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {

		//PC banner样式
		#list {
			width: 100%;
			height: 400px;
			margin-top: 3%;

			#list_main {
				width: 80%;
				height: 100%;
				margin-left: 10%;
				text-align: center;
				padding-top: 2%;

				p {
					font-size: 30px;
					color: #fd3f31;
				}

				#list_main_main {
					width: 100%;
					height: 80%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;

					#flex_box {
						width: 33%;
						height: 30%;
						background-color: #FFF000;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 22px;
						background-color: #fd3f31;
						border-radius: 5px;
						color: #FFFFFF;
						box-shadow: 3px 2px 11px #e2d2d2;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#list {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {

		//PC banner样式
		#whatbox {
			width: 100%;
			height: 500px;
			background-color: #f5f8fb;
			margin-top: 3%;

			#whatbox_main {
				width: 50%;
				height: 100%;
				margin-left: 25%;
				text-align: center;
				padding-top: 2%;

				p {
					font-size: 30px;
				}

				#whatbox_main_box {
					width: 100%;
					height: 87%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					#whatbox_main_box_flex {
						width: 31%;
						height: 95%;
						border-top: 2px solid #fd3f31;
						padding-top: 3%;
						border-left: 1px solid #dcdcdc;
						border-right: 1px solid #dcdcdc;
						border-bottom: 1px solid #dcdcdc;
						box-shadow: 2px 5px 5px #dcdcdc;
						transition: all .8s;
						background-color: #FFFFFF;
						#tt {
							width: 100%;
							height: 30%;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 20px;
						}

						img {
							height: 66%;
							max-width: 100%;
						}
					}
					#whatbox_main_box_flex:hover{
						width: 33%;
						height: 100%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#whatbox {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {

		//PC banner样式
		#funbox {
			width: 100%;
			height: 800px;
			margin-top: 5%;

			#bgc {
				width: 100%;
				height: 50%;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/funimgbgc.jpg');
				background-size: 100% 100%;
				text-align: center;
				padding-top: 4%;
			}

			#bott {
				height: 50%;
				position: relative;

				#bott_main {
					width: 70%;
					height: 130%;
					background-color: #FFFFFF;
					margin-left: 15%;
					position: absolute;
					top: -45%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					#icobox {
						width: 19%;
						height: 49%;
						text-align: center;
						padding-top: 5%;

						.iconclas {
							font-size: 80px;
							margin-bottom: 10%;
							color: #fd3f31;
						}

						p {
							font-weight: bold;
						}
					}
				}
			}

		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#funbox {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {

		//PC banner样式
		#flowchart {
			width: 100%;
			height: 600px;

			#flowbox {
				width: 80%;
				margin-left: 10%;
				height: 100%;
				text-align: center;
				padding-top: 2%;

				span {
					font-size: 32px;
					display: block;
				}

				img {
					max-width: 100%;
					max-height: 60%;
					margin-top: 3%;
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#flowchart {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {

		//手机 banner样式
		#issbox {
			width: 100%;
			height: 1000px;
			background-color: #f5f8fb;

			#issbox_main {
				width: 80%;
				height: 100%;
				margin-left: 10%;

				#issbox_tit {
					height: 15%;
					width: 100%;
					text-align: center;
					padding-top: 2%;

					p {
						font-size: 30px;
					}
				}

				#issbox_main_box {
					width: 80%;
					height: 71%;
					border: 1px solid #cccccc;
					margin-left: 10%;
					margin-top: 1%;
					display: flex;
					justify-content: center;
					align-items: center;

					#issbox_main_box_left {
						width: 40%;
						height: 100%;

						#title_le {
							background-color: #fd3f31;
							width: 100%;
							height: 15%;
							color: #FFFFFF;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 18px;
							border-right: 1px solid #FFFFFF;
						}

						#title_main {
							width: 100%;
							height: 17%;
							border-bottom: 1px solid #c2c2c2;
							display: flex;
							justify-content: flex-start;
							padding-left: 5%;
							align-items: center;
							font-size: 18px;
							border-right: 1px solid #c2c2c2;
							// background-color: #999;
						}
					}

					#issbox_main_box_right {
						width: 60%;
						height: 100%;
						text-align: center;
						overflow: hidden;

						#title_ri {
							background-color: #fd3f31;
							width: 100%;
							height: 15%;
							color: #FFFFFF;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 18px;
						}

						#title_ri_mian {
							width: 100%;
							height: 15%;
							text-align: center;
							margin-top: 2%;
						}

						img {
							max-width: 100%;
							height: 60%;
							margin-top: 5%;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#issbox {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {

		//PC样式
		#bottom {
			width: 100%;
			height: 600px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/jxbgc.jpg');
			background-size: 100% 100%;

			#bottom_bgi {
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.7);

				#bottop {
					text-align: center;
					color: #FFFFFF;
					padding-top: 2%;
				}

				#bottom_flex {
					width: 80%;
					height: 50%;
					margin-top: 2%;
					margin-left: 10%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					#flex_box_bott {
						width: 24%;
						height: 95%;
						background-color: #FFFFFF;
						text-align: center;
						transition: all .8s;
						img {
							max-width: 100%;
							height: 70%;
						}

						p {
							width: 90%;
							margin-left: 5%;
						}
					}
					#flex_box_bott:hover{
						width: 26%;
						height: 100%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#bottom {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {

		//手机 banner样式
		#fobox {
			width: 100%;
			height: 150px;
			background-color: #2D82DD;
			text-align: center;
			padding-top: 1%;
			color: #FFFFFF;
		}
	}

	@media screen and (max-width: 720px) {

		//手机 banner样式
		#fobox {
			display: none;
		}
	}

	// 手机页面样式设计
	@media screen and (max-width: 720px) {

		//手机 banner样式
		#Phone_jxc_tit_img {
			width: 100%;
			height: 300px;
			text-align: center;
			margin-top: 5%;

			img {
				max-width: 100%;
				height: 80%;
			}

			p {
				height: 20%;
				display: flex;
				font-size: 22px;
				justify-content: center;
				align-items: center;
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_jxc_tit_img {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_jxc_tit_btn {
			width: 100%;
			height: 60px;
			background-color: #fd3f31;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			p {
				width: 50%;
				margin: 0;
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_jxc_tit_btn {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_Hy_box {
			width: 100%;
			height: 700px;
			text-align: center;
			margin-top: 5%;

			p {
				width: 100%;
				font-size: 22px;
			}

			#Phone_Hy_box_flex {
				width: 100%;
				height: 87%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
			}

			#Phone_Hy_box_flex_box {
				width: 49%;
				height: 20%;
				padding-top: 3%;

				.iconclas {
					font-size: 50px;
					margin-bottom: 2%;
					color: #fd3f31;
				}

				p {
					font-size: 20px;
					margin: 0;
					font-weight: bold;
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_Hy_box {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_what_jxc_box {
			width: 100%;
			height: 600px;
			text-align: center;
			margin-top: 20px;

			p {
				font-weight: bold;
				font-size: 22px;
			}

			#Phone_what_jxc_box_flex {
				width: 100%;
				height: 87%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				#Phone_what_jxc_box_flex_box {
					width: 49%;
					height: 49%;
					box-shadow: 2px 2px 10px #e2e2e2;
					border-radius: 5px;

					p {
						font-weight: normal;
						font-size: 17px;
						height: 40%;
						margin: 0;
					}

					img {
						max-width: 100%;
						height: 60%;
					}
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_what_jxc_box {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_mode_box {
			width: 100%;
			height: 1200px;

			#phone_title {
				width: 100%;
				height: 25%;
				text-align: center;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				align-content: center;
				color: #FFFFFF;
				background-color: #fd3d31;

				#first_child {
					font-size: 25px;
				}
			}

			#Phone_mode_box_flex {
				width: 100%;
				height: 75%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				#Phone_mode_box_flex_box {
					width: 50%;
					height: 20%;
					text-align: center;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					align-content: center;

					.iconclas {
						display: block;
						height: 60%;
						font-size: 60px;
						color: #fd3f31;
					}

					p {
						width: 100%;
						height: 20%;
						margin: 0;
						font-weight: bold;
					}

					span {
						display: block;
						width: 80%;
						font-size: 14px;
					}
				}
			}

		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_mode_box {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_flow_img {
			width: 100%;
			height: 500px;

			#Phone_flow_tit {
				width: 100%;
				height: 20%;
				text-align: center;
				font-size: 22px;
				font-weight: bold;
				background-color: #fd3f31;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #FFFFFF;
			}

			img {
				max-width: 100%;
				height: 80%;
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_flow_img {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_issue {
			width: 100%;
			height: 500px;

			#Phone_issue_header {
				width: 100%;
				height: 15%;
				background-color: #fd3d31;
				color: #FFFFFF;
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			#Phone_issue_main {
				width: 100%;
				height: 85%;

				#Phone_issue_main_box {
					width: 100%;
					height: 20%;
					text-align: center;
					border-bottom: 1px solid #F2F2F2;
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_issue {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_solve_box {
			width: 100%;
			height: 800px;

			#Phone_solve_box_header {
				width: 100%;
				height: 12%;
				background-color: #fd3f31;
				color: #FFFFFF;
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			#Phone_solve_box_main {
				width: 100%;
				height: 88%;
				text-align: center;

				#Phone_solve_box_main_tit {
					text-align: center;
					padding-top: 10%;
					padding-left: 3%;
					padding-right: 3%;

					p {
						font-size: 20px;
						font-weight: bold;
					}
				}

				img {
					margin-top: 10%;
					max-width: 100%;
					height: 70%;
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_solve_box {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_benefit_box {
			width: 100%;
			height: 600px;
			margin-top: 5%;

			#Phone_benefit_box_title {
				width: 100%;
				height: 20%;
				background-color: #fd3f31;
				text-align: center;
				color: #FFFFFF;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;

				p {
					font-size: 22px;
				}
			}

			#Phone_benefit_box_flex {
				width: 100%;
				height: 79%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;

				#Phone_benefit_box_flex_box {
					width: 49%;
					height: 49%;
					text-align: center;
					box-shadow: 2px 2px 10px #e2e2e2;
					padding-left: 3%;
					padding-right: 3%;

					img {
						height: 75%;
						max-width: 100%;
					}

					p {
						margin: 0;
						font-size: 13px;
					}
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_benefit_box {
			display: none;
		}
	}

	@media screen and (max-width: 720px) {

		//手机样式
		#Phone_jxc_tit_Title_box {
			width: 100%;
			height: 100px;
			margin-top: 5%;
			text-align: center;
		}
		/deep/.el-carousel__container{
			height: 200px !important;
		}
	}

	@media screen and (min-width: 720px) {

		//电脑样式
		#Phone_jxc_tit_Title_box {
			display: none;
		}
	}
</style>
